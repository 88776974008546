// SHARED
$footerHeight: 7.5rem;

// SIZES
$break-point-xs: 576px;
$break-point-sm: 768px;
$break-point-md: 976px;
$break-point-lg: 1280px;
$break-point-xl: 1920px;

// COLORS
$dk-grey: #666666;
$md-grey: #999999;
$lt-grey: #E6E6E6;

$offwhite: #F7F7F7;

$blue: #0A6AAD;
$orange: #F77A1E;
$red: #C31D21;
$green: green;