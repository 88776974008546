@import "_variables";
@import "_breakpoints";

html,
body {
    font-size: 10px;
    line-height: 1;
    font-weight: 300;
    font-display: swap;
    color: $dk-grey;
    font-family: 'Montserrat', sans-serif;
}

p {
    line-height: 1.25;
}

strong {
    font-weight: 500;
}

#root {
    font-size: 1.6rem;
    &>.container-fluid, &>.container{
        min-height: calc(100vh - #{$footerHeight});
    }
}

h1{
    color: $blue;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 2em;
    margin-bottom: 1em;
}

a.h1 {
    color: $blue;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 2.4rem;
    margin-bottom: 1em;
    display: block;
    svg, i {
        color: $lt-grey;
        font-size: .65em;
        vertical-align: .5em;
        margin-left: .5em;
    }
    &:hover {
        color: $blue;
        text-decoration: underline;
        svg, i {
            color: $blue;
        }
    }
}

h3 {
    color: $md-grey;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .1em;
    padding-bottom: .5em;
    &.border-bottom {
        border-bottom-width: 3px !important;
        display: flex;
        justify-content: space-between;
        .popover {
            left: unset !important;
            right: 2.2em;
            top: -.25em;
            font-size: 1.25rem;
            .popover-body {
                box-shadow: 2px 2px 7px rgba(100,100,100,.2);
            }
        }
    }

    .btn{
        color: #a9a9a9;
        border-color: #a9a9a9;
        margin-top: -1em;
        padding-left: 1em;
        padding-right: 1em;
        text-transform: none;
        &:hover{
            background-color: $blue;
            color: white;
            border-color: $blue;
        }
    }
}

.notice {
    background-color: $blue;
    color: white;
    padding: 1rem 1.5rem;
}

.btn-label {
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: .1rem;
    color: $blue !important;
    &:hover{
        color: $red !important;
        background-color: none !important;
        text-decoration: underline;
    }
}

label, .label{
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: .1rem;
}

a, .a-style {
    color: $md-grey;
    &:hover{
        color: $dk-grey;
        text-decoration: none;
        cursor: pointer;
    }
}

.clickable {
    :hover {
        cursor: pointer;
    }
}


.form-control{
    border-color: transparent;
    border-bottom-color: $dk-grey;
    font-size: 1.6rem;
}

.form-group{
    padding-bottom: .5em;
    .active-item {
        margin: 1rem 1rem 1rem 0;
        padding: .5rem 1rem;
        background: $blue;
        color: white;
        display: inline-block;
        border-radius: 5px;
        cursor: pointer;
        i, svg {
            margin-left: .5rem;
            pointer-events: none;
        }
    }
}

.email-prefs {
    ul {
        margin-left: 0;
        padding-left: 0;
        list-style: none;
        li {
            line-height: 1.5;
        }
    }
    .edit {
        display: flex;
        margin-top: 2rem;
        svg {
            margin-right: 1rem;
        }
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.form-options {
    .radio-options {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        .option {
            width: 30px;
            align-items: top;
            justify-content: center;
            display: flex;
        }
        .info {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }
    }
}

::placeholder {
    color: $md-grey !important;
}

.map-wrapper{
    margin-bottom: 8rem;
}

.map-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $offwhite;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 1rem 2rem;
    min-height: 400px;
}

table {
    &.striped{
        tbody{
            tr:nth-child(odd){
                background-color: $offwhite;
            }
        }
    }
    td {
        padding: .5em 1em;
    }
    th{
        font-weight: 300;
        font-size: 1.2rem;
        text-transform: uppercase;
        padding: 1em;
    }
    thead {
        // border-bottom: 2px solid $lt-grey;
    }

    tr {
        // border-bottom: thin solid $lt-grey;
    }
    a{
        color: inherit;
    }
    .table-actions{
        svg:hover{
            cursor: pointer;
            color: $blue;
        }
    }
}

.MuiTablePagination-toolbar, .MuiTableCell-footer {
    padding: 0 !important;
}

.has-error {
    .MuiTableCell-body {
        color: $red;
    }
}

.is-current {
    .MuiTableCell-body {
        color: $green;
    }
}

.sidenav {
    .sidenav-item {
        &.active {
            color: $blue;
            font-weight: 500;
            i, svg {
                margin-left: .5em;
            }
        }
        margin-bottom: 2rem;
        display: block;
    }
}

.btn{
    text-align: center;
    border-radius: 2em;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .1em;
    color: $md-grey;
    cursor: pointer;
}

.btn-block{
    background-color: $offwhite;
    color: $blue;
    padding: .5em;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    label{
        margin: 0;
        font-size: 1.3rem;
        font-weight: 400;
    }
    &:hover{
        background: $lt-grey;
    }
}

.btn-blue{
    border-color: $blue;
    color: $blue;
    &:hover{
        color: white;
        background-color: $blue;
    }
}

.btn-red {
    border-color: $red !important;
    background-color: $red !important;
    color: white !important;
    &:hover {
        color: $red !important;
        background-color: transparent !important;
    }
}

.btn:disabled {
    background-color: $blue;
    color: white;
    pointer-events: none;
    opacity: .6;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-start {
    display: flex;
    justify-content: flex-start;
}

.justify-end {
    display: flex;
    justify-content:  flex-end;
}

.w-100{
    width: 100%;
}

.col-25{
    flex: 0 0 25% !important;
}

.col-50{
    flex: 0 0 50% !important;
}

.col-75{
    flex: 0 0 75% !important;
}

.blue{
    color: $blue;
}

.red, .active-fault, .active{
    color: $red;
}

.orange, .needs-attention, .warning{
    color: $orange;
}

.light{
    font-weight: 300;
}

.bold{
    font-weight: 500;
}

#header {
    height: 6rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;

    .optimus-logo {
        width: 235px;
        height: 6rem;
        img {
            max-width: 100%;
            max-height: 100%;
        }        
    }

    &.portrait{
        .optimus-logo{
            height: 10rem;
            margin-bottom: -4rem;
        }
    }
    .navbar {
        display: flex;
        align-items: center;
        position: relative;
        a,
        span {
            color: $dk-grey;
            margin-left: 2em;
            padding-bottom: 1em;
            border-bottom: 3px solid transparent;
            label {
                margin-left: .8em;
                cursor: pointer;
            }
            &:hover {
                color: $blue;
                border-color: $blue;
            }
            &.active{
                color: $blue;
                border-color: $blue;
                font-weight: 500;
            }
        }
        .navbar-toggle {
            display: none;
        }
        @include respond-to-max('laptop') {
            .navbar-toggle {
                display: block;
                font-size: 2rem;
                .icon-open {
                    display: block;
                }
                .icon-close {
                    font-size: 3rem;
                    color: white;
                    position: fixed;
                    top: 1em;
                    right: 1em;
                    z-index: 1001;
                    display: none;
                }
                &.expanded {
                    .icon-open {
                        display: none;
                    }
                    .icon-close {
                        display: block;
                    }
                }
            }
            #main-nav {
                position: fixed;
                top: 0;
                left: -100vw;
                width: 100vw;
                height: 100vh;
                background: $blue; 
                z-index: 1000;
                max-width: unset;
                pointer-events: none;
                transition: left 0.3s ease;
                &.expanded {
                    pointer-events: all;
                    left: 0;
                }
                .links-wrapper {
                    width: 100%;
                    & > .col {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        a, span {
                            display: block;
                            text-align: left;
                            color: white;
                            margin-left: 0;
                            &.active {
                                font-weight: 600;
                            }
                            label {
                                font-size: 2rem;
                            }
                            i, svg {
                                font-size: 1.5em;
                            }
                        }
                    }
                }
            }
        }
    }
}

.side-nav{
    div{
        margin-bottom: 1em;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        i{
            display: none;
        }
        &.active, &:hover{
            color: $blue;
            font-weight: 500;
            i{
                display: block;
            }
        }
    }
}

.widget {
    margin-bottom: 4rem;
    i{
        font-size: 4rem;
        margin: 0 .125em;
    }
    .widget-header {
        text-align: center;
        letter-spacing: .1em;
        font-weight: 500;
    }

    .widget-wrapper {
        padding: 1rem .5rem;
    }

    .callout {
        color: $blue;
        text-align: center;

        span {
            font-size: 3em;
            font-weight: 500;
            padding: 0 .125em;
        }

        label {
            font-weight: 600;
            letter-spacing: .1em;
        }
    }

    .widget-table-row {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        margin-bottom: 1em;
        &>div {
            display: flex;
            align-items: flex-start;
            flex: 1 1 auto;
            &>div {
                padding: 0 .5em;
                flex: 0 0 50%;
            }
        }
        .btn{
            display: inline-block;
            flex: 0 0 auto;
            padding: .25em 2em;
        }
    }
    &.vehicle-data {
        .widget-table-row {
            color: #666;
            font-weight: 300;
            & > div:last-child {
                font-weight: 500;
            }
        }
    }
}

.site-logo-wrapper {
    margin: 2rem 0;
    border: thin solid #CCC;
    padding: 1em;
    text-align: center;
}

.gas-gauge {
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    svg {
        flex: 1 1 auto;
    }

    span {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0 1em;
    }
}


.circle-wrapper {
    position: relative;
    margin: 2rem auto;
    width: 20rem;
    span {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2.75rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue;
    }
}

.modal {
    z-index: 50000;
    .modal-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
    .modal-backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .5);
    }

    .modal-dialog {
        z-index: 2;
    }

    .modal-body {
        padding: 5rem 5rem 2rem 5rem;
    }
    .truck-list{
        column-count: 3;
        margin-bottom: 2em;
        .truck-select{
            padding: .75em;
        }
    }
}

.client-details{
    margin-bottom: 8rem;
    &>div{
        margin-bottom: 2.5rem;
    }
}

.mui-table{
    margin-bottom: 1rem !important;
    margin-bottom: 8rem;
    &>div{
        box-shadow: none;
    }
    .MuiTableCell-root{
        font-size: 1.3rem;
        font-family: 'Montserrat', sans-serif;
        @include respond-to-max('laptop') {
            padding: 5px 15px;
        }
    }
    .MuiSelect-select{
        border: thin solid $lt-grey;
        border-radius: .2em;
    }
    .MuiSelect-icon{
        top: calc(50% - .55em);
    }
    td.MuiTableCell-root {
        height: auto !important;
    }
    td{
        border-bottom: none;
        p{
            font-size: 1.1rem;
            color: $md-grey;
            text-transform: uppercase;
        }
    }
    th.MuiTableCell-root{
        font-size: 1rem;
        color: $md-grey;
    }
    tr:nth-child(odd){
        background: $offwhite;
    }
    tr:nth-child(odd), tr:nth-child(even){
        &:hover{
            background: $lt-grey;
        }
    }
    tr.MuiTableRow-footer{
        background: white;
        td{
            border: none;
        }
        &:hover {
            background: transparent !important;
        }
    }
    .btn-outline-secondary {
        border-color: #999;
        max-width: 120px;
        font-weight: 600;
        &:hover {
            border-color: #0A6AAD;
            background-color: #0A6AAD;
        }
    }
    @include respond-to-max('laptop') {
        tr.MuiTableRow-root {
            border: thin solid $lt-grey;
        }
    }
}

#footer {
    background: $offwhite;
    min-height: $footerHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .optimus-logo {
        width: 135px;

        img {
            width: 100%;
        }
    }
    .footer-details {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
            margin: 0 1em;
        }
    }
    @include respond-to-max('laptop') {
        flex-direction: column;
        padding: 1rem 0;
        .footer-details {
            flex-direction: column;
            margin-top: 1em;
        }
    }
}
