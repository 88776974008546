.file-drop-area{
    margin: 2rem 0;
    label{
        display: block;
    }
    input{
        display: none; /* NOTE: TODO: Show this on mobile probably */
    }
    .dragarea-wrapper{
        display: block;
        position: relative;
        width: 100%;
        max-width: 500px;
        min-height: 10rem;
        text-align: center;
        cursor: pointer;
        .dragarea-mssg{
            padding: 1em 2em;
            border: dashed grey thin;
        }
        .dragarea-target{
            border: dashed grey thin;
            background-color: rgba(200,200,200,.8);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9999;
        }
        .dragarea-label{
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            text-align: center;
            color: grey;
        }
    }
}

.site-logo-wrapper {
    .thumbnail {
        max-width: 100%;
        max-height: 200px;
    }
}

.client-image {
    max-width: 100%;
    max-height: 200px;
}

.pointer {
    &:hover {
        cursor: pointer;
    }
}